import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
//import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './context/AuthProvider'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ProSidebarProvider } from 'react-pro-sidebar'
import { Accessibility } from 'accessibility'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
const App = lazy(() => import('./App.js'))

var options = {
  session: { persistent: false },
  // textPixelMode: true,
  icon: {
    position: {
      bottom: { size: 50, units: 'px' },

      left: { size: 0, units: 'px' },

      type: 'fixed',
    },
  },
}
var acc = new Accessibility(options)
window.addEventListener(
  'load',
  function () {
    console.log(document.getElementsByClassName('_menu-reset-btn'))

    var els = document.getElementsByClassName('_access-scrollbar')
    for (const num in els[0].childNodes) {
      if (num != 0 && num != 1) {
        var el = els[0].childNodes[num]
        if (el.style) {
          el.style.display = 'none'
        }
      }
    }
  },
  false
)
// var options = {
//   session: { persistent: false },
//   icon: {
//     position: {
//       bottom: { size: 50, units: 'px' },
//       left: { size: 0, units: 'px' },
//       type: 'fixed',
//     },
//   },
// }

// window.addEventListener(
//   'load',
//   function () {
//     new Accessibility(options)
//   },
//   false
// )
// options.modules = {
//   decreaseText: [true],
//   increaseText: [false],
//   invertColors: [false],
//   increaseTextSpacing: [false],
//   decreaseTextSpacing: [false],
//   increaseLineHeight: [false],
//   decreaseLineHeight: [false],
//   grayHues: [false],
//   underlineLinks: [false],
//   bigCursor: [false],
//   readingGuide: [false],
//   textToSpeech: [false],
//   speechToText: [false],
//   disableAnimations: [false],
// }
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <ProSidebarProvider>
        <Suspense fallfallback={<div>Loading...</div>}>
          <Routes>
            <Route path='/*' element={<App />} />
          </Routes>
        </Suspense>
      </ProSidebarProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
